import { useState } from 'react';
import { useRouter } from 'next/router';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useAccount } from 'frontastic';
import { addItem, updateItem } from 'frontastic/actions/cart';
import useTrack from '../hooks/useTrack';
import QuickRFQ from '../quote-create/quote-create';
export default function ProductSellers({
  product,
  onAddToCart,
  variant,
  total,
  handleStarRating,
  vendorList,
  searching,
  productQuantity,
  starRating,
  review,
  pdpWeight,
  mercQuantity,
  userNotes,
  userRdd,
}) {
  const specifications = product?.variants[0].attributes;
  //Component states
  const [loading, setLoading] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);
  const [showQuoteCreate, setShowQuoteCreate] = useState<boolean>(false);
  const router = useRouter();
  const { account } = useAccount();
  const { trackAddToCart } = useTrack({ product });
  const notesBody = {
    custom: {
      typeId: '95b5e82b-87d4-4ebb-ac12-a14627dde357',
      fields: {
        notes: userNotes,
      },
    },
  };
  const handleAddToCart = async () => {
    setLoading(true);
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      addItem(variant, productQuantity, userNotes, userRdd).then((response) => {
        setLoading(false);
        setAdded(true);
        trackAddToCart();
        router.push('/cart');
        setTimeout(() => {
          setAdded(false);
        }, 1000);
      });
    }
  };

  const handleAddToProject = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    }
  };

  const handleRequestForQuote = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      setShowQuoteCreate(true);
    }
  };
  const handleVendor = (item) => {
    router.push({ pathname: `/${item.key}/p/${item.key}` }, undefined, { scroll: false });
  };
  const selectVendor = (clickedProduct) => {
    router.push(`/search?q=+${clickedProduct?.name}`);
  };
  const filteredVendorList = vendorList.filter((item) => {
    return (
      item.masterData.current.variants[0].attributes.find((attr) => attr.name === 'vendor-name').value['en-IN'] !==
      specifications?.['vendor-name']
    );
  });
  return (
    <div>
      <div className="pdp-seller-details  relative z-10 lg:mx-[10px] lg:w-auto 2xl:mx-[25px]">
        <div className="pdp-card">
          <div className="seller-details">
            <p className="seller-name">Sold and Fulfilled by :</p>
            <p className="seller-value underline">{specifications?.['vendor-name']}</p>
            <div className="add-rfq">
              <button className="rfq" onClick={handleRequestForQuote}>
                Request for quote
              </button>
              {showQuoteCreate && (
                <QuickRFQ
                  setOpenModal={setShowQuoteCreate}
                  product={product}
                  starRating={starRating}
                  review={review}
                  variant={variant}
                  productQuantity={productQuantity}
                  pdpWeight={pdpWeight}
                />
              )}
              <button
                className={product?.price?.centAmount != 0 && mercQuantity != 0 ? 'add-cart' : 'add-cart bg-gray-400'}
                onClick={handleAddToCart}
                // disabled={product?.price?.centAmount != 0 ? false : true}
                disabled={mercQuantity == 0 ? true : false}
              >
                Add to Cart
              </button>
            </div>

            <div className="project-add" onClick={handleAddToProject}>
              <button className="add-project">Add to Project</button>
            </div>
          </div>
        </div>
        {vendorList?.length > 1 && (
          <div className="more-sellers">
            <div className="other-seller">
              <p className="sellers">Other Sellers on Allverz ({filteredVendorList?.length})</p>
            </div>
            <div className="vl"></div>
            {filteredVendorList &&
              filteredVendorList?.slice(0, 3).map((item) => {
                return (
                  <div className="" key={item.id}>
                    {item?.reviewRatingStatistics && <div className="pdp-rating">{handleStarRating(item?.key)}</div>}
                    <div className="more-seller">
                      <p className="seller-name">Sold and Fulfilled by :</p>
                      <p className="seller-value underline">
                        {
                          item.masterData.current.variants[0].attributes.find((attr) => attr.name === 'vendor-name')
                            .value['en-IN']
                        }
                      </p>
                    </div>
                    <div className="stock">
                      <p>In Stock</p>
                    </div>
                    <div className="price-unit">
                      {searching
                        ? CurrencyHelpers.formatForCurrency(
                            item.masterData.current.masterVariant.prices[0].value.centAmount,
                          )
                        : CurrencyHelpers.formatForCurrency(
                            item.masterData.current.masterVariant.prices[0].value.centAmount,
                          )}
                      /unit
                      {}
                    </div>
                    <div className="view-detail">
                      <button className="view" onClick={() => handleVendor(item)}>
                        View Details
                      </button>
                    </div>

                    <div className="sellers-vl"></div>
                  </div>
                );
              })}

            <div className="view-more">
              <button className="see-more" onClick={() => selectVendor(product)}>
                See More
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
