import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useState } from 'react';
import Typography from 'components/commercetools-ui/typography';
import MegaMenuContent from './mega-menu-content';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { STATIC_GET_MEGA_MENU_DETAIL } from 'helpers/constants/statisURLs';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function MegaMenu({ MegaMenuData }) {
  const [isVisible, setIsVisible] = useState(true);
  const [customerId,setCustomerId] = useState('');
  let lastScrollTop = 0;
  const heightToHideFrom = 100;
  const customerGroupId = localStorage.getItem("customerGroup")
  setTimeout(() => {
          setCustomerId(
                localStorage.getItem("customerGroup")
          )
  }, 2000); 
  useEffect(() => {
   window.addEventListener("scroll", function(){
    if (window.location.href.includes('/vendor-list')) {
        setIsVisible(true);
        return;
      }
    const st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop && st > heightToHideFrom) {
       setIsVisible(false);
    } else if (st < lastScrollTop) {
      setIsVisible(true);
     }
    lastScrollTop = st <= 0 ? 0 : st; 
 }, false);
  }, [])
  const [megaMenuItems, setMegaMenuItems] = useState([]);
  const handleMegaMenuItems = async () => {
    try {
      if(customerGroupId){
              const res = await backendAPIClient.get(STATIC_GET_MEGA_MENU_DETAIL+`?customerGroupId=${customerGroupId}`)
              // const res = await backendAPIClient.get(STATIC_GET_MEGA_MENU_DETAIL+`?customerGroupId=d498936e-8be4-456d-881f-f5e1097d1d48`)
              setMegaMenuItems(res.data);
            }else{
               const res = await backendAPIClient.get(STATIC_GET_MEGA_MENU_DETAIL);
               setMegaMenuItems(res.data);
              }
    } catch (err) {
      console.log(err);
    }
  }
    useEffect(() => {
      handleMegaMenuItems();
    },[customerId])
    const router = useRouter()
    const[show,setShow]  = useState(false)
  return (
    <div className="relative">
      {
   isVisible && (
      <nav aria-label="Top" className="mega_menu_header absolute mx-auto hidden max-w-full lg:block transition-all"  id="hide">
        {/* Secondary navigation */}
        <div className="h-full">
          <div className="headerMegaMain flex items-center justify-between">
            {/* Mega menus */}
            <Popover.Group className="hidden lg:block lg:flex-1 lg:self-stretch">
              <div className="flex h-full justify-center xl:space-x-8">
                {megaMenuItems &&
                  megaMenuItems?.map((category, categoryIdx) => (
                    <Popover key={category.id} className="flex">
                      {({open}) => (
                        <>
                          <div className="headerMegaItems relative flex">
                            <Popover.Button
                           
                              className={classNames(
                                open ? 'border-accent-200 text-accent-200' : 'text-accent-100 hover:text-accent-200',
                                'relative z-10 -mb-px flex items-center transition-colors duration-200 ease-out',
                              )}
                            >
                              {/* <p onClick={() => {(category?.name == 'API' || category?.name == 'All')  ? setShow(true) :  router.push('/l1-products') && setShow(false)}}>{category.name}</p> */}
                              <p onClick={() => {(category?.name == 'Chemicals' || category?.name == 'MRO' || category?.name == 'API')  ? setShow(true) :  router.push('/l1-products') && setShow(false)}}>{category.name}</p>
                            </Popover.Button>
                          </div>
                         {show && (
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                           
                            <Popover.Panel className={show ? 'mega_menu_popup absolute inset-x-0 top-full z-10 mx-auto inline-table text-gray-500 sm:text-sm' : 'hidden'}>
                              {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                              <div
                                className="absolute inset-0 top-1/2 rounded-b-lg bg-white shadow"
                                aria-hidden="true"
                              />

                              <MegaMenuContent category={category} categoryIdx={categoryIdx} />
                            </Popover.Panel>
                           
                          </Transition>
                           )}
                        </>
                      )}
                    </Popover>
                  ))}
              </div>
            </Popover.Group>
          </div>
        </div>
      </nav>
   )}
    </div>
  );
}

export default MegaMenu;
