import React, { useEffect, useState, useContext } from 'react';
import NextLink from 'next/link';
import router, { useRouter } from 'next/router';
import { StarIcon } from '@heroicons/react/solid';
import { Product } from '@Types/product/Product';
import { PLP_PRODUCT_CLICKED } from 'helpers/constants/events';
import { commercetoolsAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { UserContext } from 'frontastic/lib/renderer';
import { useAccount } from '../../../../frontastic';
import { CurrencyHelpers } from '../../../../helpers/currencyHelpers';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import Certificates from './certificates';

export interface Props {
  // items: any;
  products: Product[];
  category: string;
  totalProducts: number;
}
export default function ProductTiles({ products, totalProducts }: Props) {
  const { contextValue, setContextValue } = useContext(UserContext);
  const [ratings, setRatings] = useState({});
  const [quantities, setQuantities] = useState(Array(totalProducts).fill(1));
  const [selectedVariant, setSelectedVariant] = useState('');
  const [weightChanged, setWeightChanged] = useState(false);
  const [rows, setRows] = useState([{ value: null, error: '' }]);
  function decreaseValue(index) {
    const newQuantities = [...quantities];
    if (newQuantities[index] > 1) {
      newQuantities[index] = newQuantities[index] - 1;
      contextValue.quantity = newQuantities[index];
      setQuantities(newQuantities);
      setContextValue({ ...contextValue });
    }
  }
  function increaseValue(index) {
    const newQuantities = [...quantities];
    newQuantities[index] = newQuantities[index] + 1;
    contextValue.quantity = newQuantities[index];
    setQuantities(newQuantities);
    setQuantities(newQuantities);
  }
  const handleQuantitychange = (e, index) => {
    const newQuantities = [...quantities];
    newQuantities[index] = e.target.value;
    setQuantities(newQuantities);
    let copy = [...rows];
    if (isNaN(e.target.value) || e.target.value == 0 || e.target.value == '') {
      copy = [...copy, { value: index, error: 'Minimum Quantity should be 1' }];
      setRows(copy);
    } else {
      copy.splice(
        copy.findIndex((f) => f.value === index),
        1,
      );
      setRows(copy);
    }
    setRows(copy);
  };
  useEffect(() => {
    setQuantities(Array(totalProducts).fill(1));
  }, [products[0].slug]);
  const [isFiltering, setIsFiltering] = useState<boolean>(false);

  const { asPath } = useRouter();
  const { account } = useAccount();

  const handleAddToProject = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      // handle add to project here
    }
  };

  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const toggleFiltering = () => {
    setIsFiltering(!isFiltering);
  };
  const handleNavigateToPDP = (product: Product) => {
    setTimeout(() => {
      if (weightChanged) {
        gtag('event', PLP_PRODUCT_CLICKED, product);
        router.push({ pathname: `/${product?.slug}/p/${selectedVariant}` }, undefined, {
          scroll: false,
        });
      } else {
        gtag('event', PLP_PRODUCT_CLICKED, product);
      }
    }, 300);
  };

  useEffect(() => {
    if (products?.length > 0) {
      const duplicateRating = {};

      products.forEach((el) => {
        if (el?.reviewRatingStatistics) {
          duplicateRating[el?.slug] = Math.round(el?.reviewRatingStatistics?.averageRating);
        }
      });
      setRatings({ ...duplicateRating });
    }
  }, [products]);
  const handleStarRating = (id) => {
    if (ratings[id]) {
      const stars = [];
      for (let i = 0; i < 5; i++) {
        stars.push(<StarIcon key={i} className={`ratings ${i < ratings[id] ? 'ratings' : 'grey-rating'}`} />);
      }
      return stars;
    } else {
      return Array.from(Array(5), (_, i) => <StarIcon key={i} className="ratings grey-rating" />);
    }
  };
  const handleEmptyStarRating = (name) => {
    return Array.from(Array(5), (_, i) => <StarIcon key={i} className="ratings grey-rating" />);
  };
  const handleProductWeightChange = (e) => {
    contextValue.variant = e.target.value;
    setContextValue({ ...contextValue });
    if (e.target.value) {
      setWeightChanged(true);
    }
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const option = el.getAttribute('id');
    setSelectedVariant(option);
  };
  useEffect(() => {
    contextValue.plpWeightChanged = weightChanged;
    setContextValue({ ...contextValue });
  }, []);
  useEffect(() => {
    contextValue.plpWeightChanged = weightChanged;
    setContextValue({ ...contextValue });
  }, [weightChanged]);
  const requestForQuote = () => {
    if (!account) {
      router.push({ pathname: '/login', query: router.asPath });
    } else {
      router.push('quotes/request-for-quote');
    }
  };
  const handleDetailPageRoute = (vendorCode) => {
    if (vendorCode) {
      router.push(`/vendor-details?vendor-code=${vendorCode}`);
    } else {
      return false;
    }
  };
  return (
    <div className="plpProductTilesWrap">
      <div className="plpProductTilesMain">
        {products?.map((product, id) => (
          <div className="group my-4" key={id}>
            <div className="product-card">
              {product.variants?.slice(0, 1).map((variant) => (
                <div className="product-details" key={variant.id}>
                  <div className="plpTilesLeft">
                    <NextLink href={product._url} key={product.productId}>
                      <img
                        src={variant.images[0]}
                        alt={product.name}
                        className="product-img h-full object-cover md:block md:w-48 lg:w-96 lg:max-w-md"
                        onClick={() => handleNavigateToPDP(product)}
                      />
                    </NextLink>
                    <div className="plp-certif flex">
                      <div className="plp_certifications flex">
                        <div className="plp-verified ml-1">
                          <svg
                            width="16"
                            height="21"
                            viewBox="0 0 16 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M6.9 14.1L12.6 8.39998L11.55 7.37498L6.975 11.95L4.45 9.42498L3.35 10.525L6.9 14.1ZM8 20.975C5.66667 20.3916 3.75 19.0375 2.25 16.9125C0.75 14.7875 0 12.4583 0 9.92498V3.97498L8 0.974976L16 3.97498V9.92498C16 12.4583 15.25 14.7875 13.75 16.9125C12.25 19.0375 10.3333 20.3916 8 20.975Z"
                              fill="#32C902"
                            />
                          </svg>
                          <p className="ml-1 mt-0.5 text-xs font-medium text-[#000000]">Verified</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="line"></div>
                  <div className="attributes lg:mr-auto">
                    {product.reviewRatingStatistics && (
                      <div className="rating-mobile">{handleStarRating(product?.slug)}</div>
                    )}
                    <div className="cas-star">
                      <div className="cas-no">{variant.attributes['cas-no']}</div>
                      {product.reviewRatingStatistics && (
                        <div className="rating hidden">{handleStarRating(product?.slug)}</div>
                      )}
                    </div>
                    <NextLink href={product._url} key={product.productId}>
                      <h3 className="product-name" onClick={() => handleNavigateToPDP(product)}>
                        {product?.name}
                      </h3>
                    </NextLink>
                    <span className="category">
                      <span className="category-title">Category:</span>
                      {product &&
                        product.category &&
                        Object.entries(product.category)
                          .slice(1, 3)
                          .map(([slug, name]) => (
                            <NextLink key={slug} href={slug}>
                              <h4 className="category-value capitalize">{name}</h4>
                            </NextLink>
                          ))}
                    </span>
                    <h4 className="grade">Grade: {variant.attributes.grade}</h4>
                    <div className="pure">
                      <h4 className="purity">Purity: {variant.attributes.purity}</h4>
                    </div>
                    <div className="origin">
                      <h4 className="produced">Produced In: {variant.attributes['country-of-origin']?.label}</h4>
                    </div>

                    <div className="sold">
                      <p className="sold-by">Sold and Fulfilled by: </p>
                      <span
                        className="cursor-pointer"
                        onClick={() => handleDetailPageRoute(product.variants[0]?.attributes['vendor-code'])}
                      >
                        <p className="sold-value"> {product.variants[0]?.attributes['vendor-name']}</p>
                      </span>
                    </div>

                    <div className="add-compare">
                      <input id="compare" name="addCompare" type="checkbox" className="compare-box" />
                      <label htmlFor="remember-me" className="add-to-compare">
                        {formatMessage({ id: 'Add To Compare', defaultMessage: 'Add To Compare' })}
                      </label>
                    </div>
                  </div>
                  <div className="attributes-rht lg:ml-auto lg:mr-[10px] lg:flex">
                    <div className="plp-rit-attributes">
                      <div className="plp-whislist">
                        <div className="certificate">
                          {variant?.attributes['product-certificate'] ? (
                            <Certificates certificates={variant?.attributes['product-certificate']} />
                          ) : null}
                        </div>
                      </div>
                      <div
                        className={product?.variants[0]?.attributes['product-certificate'] ? 'prices' : 'plp-prices'}
                      >
                        {variant?.prices?.centAmount != 0 ? (
                          <p className="price">{CurrencyHelpers.formatForCurrency(variant.prices)}</p>
                        ) : (
                          <button className="first_review ask-price-btn flex" onClick={requestForQuote}>
                            Ask for price
                          </button>
                        )}
                      </div>
                      <div className="weight">
                        <select
                          id="selected-tab"
                          name="selected-tab"
                          className="mt-1 block w-full rounded-sm border-gray-300 stroke-accent-400 py-2 pr-10 pl-3 text-base text-accent-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                        >
                          {product.variants
                            .filter((_, index) => index !== 0)
                            .map((item, index) => (
                              <option className="values" key={index} defaultValue={item?.attributes['packaging-size']}>
                                {item?.attributes['packaging-size']}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="quantityMobile ">
                        <p className="qtyText plpQuantMob ml-[14px] mt-[10px] text-[#303030]">Quantity:</p>

                        <div className="gms flex gap-[10px]">
                          <div className="qty plpQTY">
                            <div className="grms-qty bg-[white]">
                              <button className="qtyminus bg-[white]" onClick={() => decreaseValue(id)}>
                                <svg
                                  width="14"
                                  height="2"
                                  viewBox="0 0 14 2"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M13.2079 2H0.992188V0H13.2079V2Z" fill="#0068A3" />
                                </svg>
                              </button>
                              <input
                                className="plp-weight plp_qty_digit"
                                type="number"
                                value={quantities[id]}
                                //  onChange={() => {}}
                                onChange={(e) => handleQuantitychange(e, id)}
                              />
                              <button className="qtyplus bg-[white]" onClick={() => increaseValue(id)}>
                                <svg
                                  width="14"
                                  height="14"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.1649 8H7.92961V14H6.18451V8H0.949219V6H6.18451V0H7.92961V6H13.1649V8Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="request-quote-error plp-qty-error lg:hidden">
                        {rows?.find((item) => item.value == id)?.error}
                      </p>
                      <div className="quantity">
                        <div className="plp-tile-weight flex gap-[10px]">
                          <select
                            id="selected-tab"
                            name="selected-tab"
                            className="mt-1 block w-full rounded-sm border-gray-300 stroke-accent-400 py-2 pr-10 pl-3 text-base text-accent-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                            onChange={(e) => handleProductWeightChange(e)}
                          >
                            {product.variants
                              .filter((_, index) => index !== 0)
                              .map((item, index) => (
                                <option
                                  className="values"
                                  key={item?.sku}
                                  id={item?.sku}
                                  defaultValue={item?.attributes['packaging-size']}
                                >
                                  {item?.attributes['packaging-size']}
                                </option>
                              ))}
                          </select>

                          <div className="qty plpQTY">
                            <div className="grms-qty bg-[white]">
                              <p>Quantity </p>
                              <button className="qtyminus bg-[white]" onClick={() => decreaseValue(id)}>
                                <svg
                                  width="12"
                                  height="2"
                                  viewBox="0 0 14 2"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M13.2079 2H0.992188V0H13.2079V2Z" fill="#0068A3" />
                                </svg>
                              </button>
                              <input
                                className="plp-weight plp_qty_digit"
                                type="text"
                                value={quantities[id]}
                                //  onChange={() => {}}
                                onChange={(e) => handleQuantitychange(e, id)}
                              />
                              <button className="qtyplus bg-[white]" onClick={() => increaseValue(id)}>
                                <svg
                                  width="12"
                                  height="12"
                                  viewBox="0 0 14 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13.1649 8H7.92961V14H6.18451V8H0.949219V6H6.18451V0H7.92961V6H13.1649V8Z"
                                    fill="#0068A3"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p className="request-quote-error plp-lg-qty-err lg:pt-[10px] lg:pb-[0px]">
                        {rows?.find((item) => item.value == id)?.error}
                      </p>
                      <div className="product-right lg:mt-[10px]">
                        <button className="project-list" onClick={handleAddToProject}>
                          Add To Project
                        </button>
                        <NextLink href={product._url} key={product.productId}>
                          <button className="add-to-cart" onClick={() => handleNavigateToPDP(product)}>
                            Know More{' '}
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="white" />
                            </svg>
                          </button>
                        </NextLink>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
