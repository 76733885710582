import React, { useCallback, useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { EyeIcon, EyeOffIcon, MailIcon, LockClosedIcon } from '@heroicons/react/outline';
import CryptoJS from 'crypto-js';
import { COMMERCETOOLS_BASE_URL, LOGIN_DATA_ENC_KEY } from 'helpers/constants/envVariables';
import { STATIC_SSO_URL } from 'helpers/constants/statisURLs';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { Reference, ReferenceLink } from 'helpers/reference';
import { backendAPIClient } from 'helpers/utils/axiosRequestsUtil';
import { useAccount } from 'frontastic';
import { loginWithOTP } from 'frontastic/actions/account';
import AzureAuth from './azure-auth/azure-auth';
import EmailVerify from './EmailVerifyModal';
import OTPEmail from './Twilio OTP/OTP-email';
import NextLink from 'next/link';
import Loader from 'components/commercetools-ui/loader';
import axios from 'axios';

export interface LoginProps {
  registerLink?: Reference;
  accountLink?: Reference;
  logoLink?: Reference;
}

const Login: React.FC<LoginProps> = ({ registerLink, logoLink }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });

  // //account actions
  // const { login, loggedIn, requestConfirmationEmail, requestPasswordReset } = useAccount();
  const { login, loggedIn, requestConfirmationEmail, requestPasswordReset } = useAccount();
  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleSSOLogin = async (SSOAccessToken) => {
    setLoading(true);
    try {
      const response = await backendAPIClient.post(
        STATIC_SSO_URL,
        {},
        { headers: { access_token: `Bearer ${SSOAccessToken}` } },
      );
      localStorage.setItem('userName', response?.data?.customer?.firstName);
      const customerEmail = response?.data?.customer.email;
      if (customerEmail && customerEmail.endsWith('@drreddys.com')) {
        const isEmailVerified = true;
        loginWithOTP(response.data.customer, isEmailVerified);
      }
      // if (response?.data?.customer) {
      //  loginWithOTP(response.data.customer);
      // }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (inProgress === InteractionStatus.None && isAuthenticated && !loggedIn) {
      const accessTokenRequest = {
        scopes: ['user.read', 'openid', 'profile', 'offline_access', 'email'],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          const accessToken = accessTokenResponse.accessToken;
          // Call your API with token
          handleSSOLogin(accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                const accessToken = accessTokenResponse.accessToken;
                handleSSOLogin(accessToken);
              })
              .catch(function (error) {
                // Acquire token interactive failure
                console.log(error);
              });
          }
          console.log(error);
        });
    }
  }, [instance, accounts, inProgress, isAuthenticated]);

  //login data
  const [data, setData] = useState({ email: '', password: '', rememberMe: false });
  const [userData, setUserData] = useState(null);
  const [passwordRequestToken, setPasswordRequestToken] = useState('');

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [showSSOLogin, setShowSSOLogin] = useState(false);

  //attempting to resend verification email
  const [resendVerification, setResendVerification] = useState(false);

  //attempting to request a password reset
  const [resendPasswordReset, setResendPasswordReset] = useState(false);

  //not on default login modal
  const subModal = resendVerification || resendPasswordReset;

  //Password is visible while typing
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [showOTPScreen, setShowOTPScreen] = useState(false);
  const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);
  //Toggles password visibility
  const togglePasswordVisibility = useCallback(() => setIsPasswordVisible(!isPasswordVisible), [isPasswordVisible]);

  //get back to login modal
  const backToLogin = () => {
    setResendPasswordReset(false);
    setResendVerification(false);
  };

  //wants to resend verification
  const toResendVerification = () => {
    setResendVerification(true);
    setResendPasswordReset(false);
  };

  //requesting a password reset
  const toResendPassword = () => {
    setResendPasswordReset(true);
    // setShowEmailVerifyModal(true)
    setResendVerification(false);
  };

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    setData({ ...data, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const encryptedLoginData: string = localStorage.getItem('loginData');
    if (encryptedLoginData) {
      const decryptedLoginData = handleDecrypt(encryptedLoginData);
      if (decryptedLoginData) {
        const loginData = JSON.parse(decryptedLoginData);
        setData({ email: loginData.email, password: loginData.password, rememberMe: loginData.rememberMe });
      }
    }
  }, []);

  useEffect(() => {
    if (data?.email.includes('drreddys.com')) {
      setShowSSOLogin(true);
    } else {
      setShowSSOLogin(false);
    }
  }, [data?.email]);

  //handle checkbox input change
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };
  // chech for customer groups
  const customerGroup = async (acountID, customerToken) => {
    try {
      const res = await axios.get(`${COMMERCETOOLS_BASE_URL}` + '/customers/' + `${acountID}`, {
        headers: {
          Authorization: `Bearer ${customerToken}`,
        },
      });
      localStorage.setItem('customerGroup', res.data.customerGroup.id);
    } catch (error) {
      console.error('Error fetching customer data:', error);
    }
  };
  //login user
  const loginUser = async () => {
    setLoginLoading(true);
    try {
      const response = await login(data.email, data.password);
      setLoginLoading(false);
      const customerToken = localStorage.getItem('token');
      if (response?.accountId) {
        customerGroup(response.accountId, customerToken);
      }
      if (response.confirmed) {
        localStorage.setItem('userName', response?.firstName);
        if (data.rememberMe) {
          localStorage.setItem('loginData', handleEncrypt(JSON.stringify(data)));
        } else {
          localStorage.removeItem('loginData');
        }
      }

      if (!response.accountId) {
        setError(formatErrorMessage({ id: 'auth.wrong', defaultMessage: 'Wrong email address or password' }));
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  //resend verification email for user
  const resendVerificationEmailForUser = async () => {
    try {
      await requestConfirmationEmail(data.email, data.password);
      setSuccess(
        formatAccountMessage({
          id: 'verification.resent',
          defaultMessage: 'An email was sent to {email}',
          values: { email: data.email },
        }),
      );
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Please Enter Registered Email Id' }));
    }
  };

  //request a password reset for user
  const resendPasswordResetForUser = async () => {
    try {
      const response = await requestPasswordReset(data.email);
      if (response.status !== 200) {
        setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Please Enter Registered Email Id' }));
      } else {
        setError('');
        setShowEmailVerifyModal(true);
        // setSuccess(
        //   formatAccountMessage({
        //     id: 'verification.resent',
        //     defaultMessage: 'An email was sent to {email}',
        //     values: { email: data.email },
        //   }),
        // );
        if (data.email) {
          setShowEmailVerifyModal(true);
        } else {
          setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Please Enter Registered Email Id' }));
        }
      }
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Please Enter Registered Email Id' }));
    }
  };

  // const validate = () => {
  //   //validation schema
  //
  //   const emailPassword = data.email && data.password
  //    //UI error messages
  //   if(!data.email){
  //       setError(formatErrorMessage({ id: 'email.notFound', defaultMessage: "Please write an email Id" }));
  //   }
  //   if(!data.password){
  //     setError(formatErrorMessage({ id: 'password.notFound', defaultMessage: "Please type Password" }));
  //   }

  //   return emailPassword;
  // };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //validate data
    //  if (!validate()) return;
    //processing starts
    setLoading(true);
    //if user is attempting to resend verification email
    if (resendVerification) resendVerificationEmailForUser();
    //if user is attempting tor equest a password reset
    else if (resendPasswordReset) {
      resendPasswordResetForUser();
      setShowEmailVerifyModal(false);
    }
    //if user wants to login
    else loginUser();
    //processing ends
    setLoading(false);
  };
  const router = useRouter();
  //loggedin user redirects to previous page
  if (loggedIn) {
    // router.push(Object.keys(router.query)[0]);
    if (Object.keys(router.query)[0] === 'slug' || Object.keys(router.query)[0] === 'path') {
      router.push('/');
    } else {
      router.push(Object.keys(router.query)[0]);
    }
    //<Redirect target='/' />
  }

  const handleEncrypt = (text: string) => {
    if (!LOGIN_DATA_ENC_KEY) return;
    try {
      return CryptoJS.AES.encrypt(text, LOGIN_DATA_ENC_KEY).toString();
    } catch (error) {
      console.error('Encryption failed:', error.message);
    }
  };

  const handleDecrypt = (encText: string) => {
    if (!LOGIN_DATA_ENC_KEY) return;
    try {
      return CryptoJS.AES.decrypt(encText, LOGIN_DATA_ENC_KEY).toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error('Decryption failed:', error.message);
    }
  };
  const handleTermsClick = () => {
    window.open('/terms-of-use', '_blank');
  };
  const handlePrivacyPolicyClick = () => {
    window.open('/privacy-policy', '_blank');
  };

  return (
    <>
      <div className="sign_in_container flex min-h-full flex-col justify-center sm:px-6 lg:px-8">
        <div className="sign-in sign_in  flex  lg:mr-auto">
          {/* <div>
                <ContentLoginSlider data={data}/>
              </div> */}
          <div className="sign-in-content  w-full   bg-white px-6 pb-32 pt-10  dark:bg-primary-200 lg:px-12">
            {!showOTPScreen ? (
              <>
                {subModal && (
                  <div className="flex">
                    <p onClick={backToLogin}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M14.8627 3.225L13.3794 1.75L5.1377 10L13.3877 18.25L14.8627 16.775L8.0877 10L14.8627 3.225Z"
                          fill="#0068A3"
                        />
                      </svg>
                    </p>
                    <button className="return_sign_in" onClick={backToLogin}>
                      Return to Sign In
                    </button>
                  </div>
                )}
                <form className="space-y-7" onSubmit={handleSubmit}>
                  <div className="sign-in-allverz text-center">
                    {/* <h2 className="text-3xl font-extrabold text-neutral-700"> */}
                    <h2 className="signin_heading lg:mt-[50px]">
                      {resendPasswordReset
                        ? formatAccountMessage({ id: 'password.reset.headline', defaultMessage: 'Reset your password' })
                        : formatAccountMessage({ id: 'account.sign.in', defaultMessage: 'Sign-in to Allverz' })}
                    </h2>

                    {!subModal ? (
                      // <h3 className="text-md mt-6 text-neutral-600">
                      <>
                        <h3 className="signin_tooltip hidden lg:block">
                          {formatAccountMessage({
                            id: 'details.enter',
                            defaultMessage: 'Already Registered? Enter your details to Sign-in',
                          })}
                          {/* <div>{error && <p className="error_login error_wrong_password">{error}</p>}</div> */}
                        </h3>
                        <h3 className="signin_tooltip block lg:hidden">
                          {formatAccountMessage({
                            id: 'details.enter.mobile',
                            defaultMessage: 'Enter your details to Sign-in',
                          })}
                          {/* <div>{error && <p className="error_login error_wrong_password">{error}</p>}</div> */}
                        </h3>
                      </>
                    ) : (
                      <h3 className=" signin_tooltip">
                        {formatAccountMessage({
                          id: 'password.reset.desc',
                          defaultMessage:
                            'To reset your password, please enter the email address associated with your account',
                        })}
                      </h3>
                    )}
                  </div>
                  {success && <p className="text-sm text-green-600">{success}</p>}
                  {/* {error && <p className="text-sm text-accent-400 error_login">{error}</p>} */}
                  {/* {error && <p className="error_login error_wrong_password">{error}</p>} */}
                  <div> {error && <p className="error_login error_wrong_password">{error}</p>}</div>
                  {/* {!subModal ? (
                ) : (
                  <div></div>
                )} */}
                  <div>
                    {/* <label htmlFor="email" className="block text-sm font-medium text-neutral-700"> */}
                    <div className="flex">
                      <label htmlFor="email" className="email_label">
                        {formatMessage({ id: 'email', defaultMessage: 'Email' })}
                      </label>
                      <span className="ml-[3px] text-[#EB4747]">*</span>
                    </div>
                    <div className="email_login relative mt-0">
                      <input
                        //   id="email"
                        name="email"
                        type="email"
                        value={data?.email}
                        autoComplete="email"
                        placeholder={formatAccountMessage({ id: 'email.enter', defaultMessage: 'Enter your email' })}
                        required
                        className="email_input block w-full appearance-none rounded-sm border border-gray-300 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                        onChange={handleChange}
                      />
                      <span className="absolute left-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600">
                        <MailIcon className="mail_icon" />
                      </span>
                    </div>
                  </div>

                  {!resendPasswordReset && (
                    <div>
                      {/* <label htmlFor="password" className="block text-sm font-medium text-neutral-700"> */}
                      <div className="flex">
                        <label htmlFor="password" className="password_label">
                          {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
                        </label>
                        <span className="ml-[3px] text-[#EB4747]">*</span>
                      </div>
                      <div className="password_login relative mt-0">
                        <input
                          // id="password"
                          name="password"
                          type={isPasswordVisible ? 'text' : 'password'}
                          value={data?.password}
                          autoComplete="current-password"
                          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                          required
                          className=" signInPassInput block w-full appearance-none rounded-sm border border-gray-300 py-2 px-3 shadow-sm placeholder:text-gray-400 focus:border-accent-400 focus:outline-none focus:ring-accent-400 sm:text-sm"
                          onChange={handleChange}
                        />
                        <span className="absolute left-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600">
                          <LockClosedIcon className="lock_closed_icon" />
                        </span>
                        <span
                          className="absolute right-3 top-1/2 block h-4 w-4 -translate-y-1/2 text-neutral-600"
                          onClick={togglePasswordVisibility}
                        >
                          {!isPasswordVisible ? <EyeOffIcon /> : <EyeIcon />}
                        </span>
                      </div>
                    </div>
                  )}

                  {subModal ? (
                    <div>
                      {/* <p>Return to Sign</p> */}
                      {/* <ArrowLeftIcon
                      className="w-4 cursor-pointer text-accent-400 hover:text-accent-500"
                      onClick={backToLogin}
                    /> */}
                    </div>
                  ) : (
                    <div className="mt-2 space-y-2">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <input
                            //   id="remember-me"
                            name="rememberMe"
                            type="checkbox"
                            checked={data.rememberMe}
                            // className="h-5 w-5 rounded-sm text-transparent focus:ring-accent-500 remember_checkbox"
                            className="remember_checkbox h-5 w-5 rounded-sm text-transparent"
                            onChange={handleCheckboxChange}
                          />
                          {/* <label htmlFor="remember-me" className="ml-2 block whitespace-nowrap text-sm text-neutral-600"> */}
                          <label htmlFor="remember-me" className="remember_me_label">
                            {formatMessage({ id: 'rememberMe', defaultMessage: 'Remember me' })}
                          </label>
                        </div>
                        {!data?.email.includes('drreddys.com') && (
                          <div className="text-right text-sm">
                            <span
                              // className="cursor-pointer text-neutral-600 underline transition hover:text-accent-400"
                              className="forgot_password cursor-pointer underline transition"
                              onClick={toResendPassword}
                            >
                              {formatAccountMessage({ id: 'password.forgot', defaultMessage: 'Forgot password?' })}
                            </span>
                          </div>
                        )}
                      </div>

                      {/* <div className="flex items-center justify-end">
                          <div className="text-sm">
                            <span
                              className="cursor-pointer font-medium text-accent-400 hover:text-accent-500"
                              onClick={toResendVerification}
                            >
                              {formatAccountMessage({
                                id: 'verification.resend',
                                defaultMessage: 'Bestätigungsmail erneut senden',
                              })}
                            </span>
                          </div>
                        </div> */}
                    </div>
                  )}
                  <div>
                    {subModal ? (
                      <div>
                        <button
                          type="submit"
                          // className="flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-sm font-medium text-white shadow-sm transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
                          className="flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-[14px] font-medium tracking-[1px] text-white shadow-sm transition-colors duration-200 ease-out disabled:bg-gray-200"
                          disabled={loading}
                        >
                          Send
                          {/* {subModal
                  
                      ? formatMessage({ id: 'send', defaultMessage: 'Send' })
                      : formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign-in' })} */}
                        </button>
                        {showEmailVerifyModal && data.email && (
                          <EmailVerify emailId={data.email} setOpenModal={setShowEmailVerifyModal} />
                        )}
                      </div>
                    ) : (
                      <>
                        {showSSOLogin ? (
                          <AzureAuth />
                        ) : (
                          <button
                            type="submit"
                            // className="flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-sm font-medium text-white shadow-sm transition-colors duration-200 ease-out hover:bg-accent-500 focus:outline-none focus:ring-2 focus:ring-accent-400 focus:ring-offset-2 disabled:bg-gray-200"
                            className="signInButtonLink flex w-full justify-center rounded-sm border border-transparent bg-accent-400 py-2 px-4 text-[14px] font-medium tracking-[1px] text-white shadow-sm transition-colors duration-200 ease-out disabled:bg-gray-200"
                            disabled={loading}
                            // onClick={userLogin}
                          >
                            Sign-in
                            {/* {subModal

? formatMessage({ id: 'send', defaultMessage: 'Send' })
  : formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign-in' })} */}
                          </button>
                        )}
                      </>
                    )}
                    {subModal ? (
                      <div className="terms_and_policy text-center lg:mt-[21px]">
                        Need Help for your email address?
                        <NextLink href="/contact-us">
                          <span className="get_banner_help cursor-pointer">Contact Us</span>
                        </NextLink>
                      </div>
                    ) : (
                      <div className="terms_and_policy">
                        By continuing, you agree to our{' '}
                        <span className="underline" onClick={handleTermsClick}>
                          Terms of Service
                        </span>{' '}
                        &{' '}
                        <span className="underline" onClick={handlePrivacyPolicyClick}>
                          Privacy Policy
                        </span>
                      </div>
                    )}
                  </div>
                </form>
                {subModal ? (
                  <div className="mt-[32px] flex flex-col items-center lg:mt-[82px]">
                    {!data?.email.includes('drreddys.com') && (
                      <>
                        <p className="password_remember">Don’t remember your password?</p>
                        <button className="signin_otp" onClick={() => setShowOTPScreen(true)}>
                          Sign-in Using OTP
                        </button>
                      </>
                    )}
                  </div>
                ) : (
                  <div
                    className={
                      error
                        ? 'mt-[32px] flex flex-col items-center lg:mt-[30px]'
                        : 'mt-[32px] flex flex-col items-center lg:mt-[52px]'
                    }
                  >
                    {!data?.email.includes('drreddys.com') && (
                      <>
                        <p className="password_remember">Don’t remember your password?</p>
                        <button className="signin_otp" onClick={() => setShowOTPScreen(true)}>
                          Sign-in Using OTP
                        </button>
                      </>
                    )}
                  </div>
                )}
              </>
            ) : (
              <OTPEmail
                setShowOTPScreen={setShowOTPScreen}
                handleResetPasswordScreen={setResendPasswordReset}
                email={data?.email}
              />
            )}

            {/* <p className="mt-4 text-center text-sm text-neutral-600 lg:mt-[55px]"> */}

            {/* {!showOTPScreen ? } */}
            <p
              className={
                error
                  ? 'new_account mt-[25px] flex justify-center  lg:mt-[50px] '
                  : 'new_account new_account_mobile mt-[32px] flex justify-center  lg:mt-[35px] '
              }
            >
              {formatAccountMessage({ id: 'account.doNotHave', defaultMessage: 'New to Allverz?' })}{' '}
              <ReferenceLink
                target={registerLink}
                //  className="font-medium text-accent-400 underline hover:text-accent-500 sign_up_btn"
                className="sign_up_btn"
              >
                {formatAccountMessage({ id: 'account.register.here', defaultMessage: 'Sign-up' })}
              </ReferenceLink>
            </p>
            <p className="trouble_login flex lg:hidden">
              Have Trouble Logging in?
              <NextLink href="/contact-us">
                <span className="get_help cursor-pointer">Get Help</span>
              </NextLink>
            </p>
          </div>
        </div>
      </div>
      {loginLoading && <Loader />}
    </>
  );
};

export default Login;
