import AlgoliaSearch from 'components/commercetools-ui/products/product-list/algolia-search/search-algolia';
import Link from 'next/link';
import React, { useState } from 'react';

const Search = ({ data }) => {
  const [triggerSearch, setTriggerSearch] = useState(false);

  const handleSearchClick = () => {
    setTriggerSearch(true);
  }

  return (
    <>
      <div className="">
        <div className="px-5 md:px-0 lg:px-3">
          <div className="search-main  container mx-auto px-5 md:px-0 lg:px-0">
            <div className="search-title">
              <h2>Find instantly what you are looking for?</h2>
            </div>
            <div className="search-type">
              <p className="catText">Categories</p>
              <div className="searchBtnWrap searchBtnWrapDesktop">
                <button className="btn-api">
                  <Link href={'/API'}>Active Pharmaceutical Ingredients (API)</Link>
                </button>
                <button className="btn-excipients">
                  <Link href={'/l1-products'}>Excipients</Link>
                </button>
                {/* <button className="btn-ksm"><Link href={""}>Intermediates</Link></button> */}
                <button className="btn-solvents">
                  <Link href={'/l1-products'}>Solvents</Link>
                </button>
              </div>
              <div className="searchBtnWrap searchBtnWrapMobile">
                <p>Categories</p>
                <button className="btn-api">
                  <Link href={'/API'}>API</Link>
                </button>
                <button className="btn-excipients">
                  <Link href={'/l1-products'}>Excipients</Link>
                </button>
                <button className="btn-ksm">
                  <Link href={''}>Intermediates</Link>
                </button>
                <button className="btn-solvents">
                  <Link href={'/l1-products'}>Solvents</Link>
                </button>
              </div>
            </div>
            <div className="search-input">
              <svg
                onClick={() => handleSearchClick()}
                className="searchIcon"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                  stroke="#003769"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.0004 21L16.6504 16.65"
                  stroke="#003769"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <AlgoliaSearch
                searchInputStyle={'search-field'}
                placeHolder={'Search by Chemical name or CAS No.'}
                showbutton={true}
                triggerSearch={triggerSearch}
              />
              {/* <input
                className="search-field"
                type="text"
                id="message"
                name="message"
                placeholder="Search by Chemical name and CAS No."
              /> */}
              {/* <button className="btn-search">Search</button> */}
            </div>
            <div className="search-sugg">
              <span style={{ color: 'white' }}>Popular Products</span>
              <div className="sugg-title">
                <button className="sugg-content">
                  <Link href={'/API'}>API</Link>
                </button>
                <button className="sugg-content">
                  <Link href={'/l1-products'}>Excipients</Link>
                </button>
              </div>
              <div className="sugg-title1">
                <button className="sugg-content">
                  <Link href={'/l1-products'}>Solvents</Link>
                </button>
                <button className="sugg-content">
                  <Link href={'/l1-products'}>Naproxen</Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
