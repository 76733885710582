import { useEffect, useState } from 'react';
export default function ProductSpecifications({ product, dataToShow, attributes }) {
  const [showAll, setShowAll] = useState(false);
  const scrollToId = (id: string) => {
    const ele: HTMLElement = document.getElementById(id);
    ele.scrollIntoView();
  };

  return (
    <div>
      <div className="specification">
        <h3 className="spec-detail">Product Details</h3>
        <p className="spec-descrip">Here you will find the detailed product specification for {product?.name}</p>
        <div className="pdp_cf_wrap1">
          {dataToShow.map(([key, value]) => (
            <>
              {key === 'grade' ||
              key === 'cas-no' ||
              key === 'purity' ||
              key === 'seo-title' ||
              key === 'seo-description' ||
              key === 'currency_code' ||
              key === 'seo-long-description' ||
              key === 'seo-keywords' ||
              key === 'molecular-formula' ||
              key === 'chemical-formula' ||
              key === 'shelf-life' ||
              key === 'country-of-origin' ||
              key === 'packaging-size' ||
              key === 'vendor-name' ||
              key === 'vendor-code' ||
              key === 'MOQ' ||
              key === 'product-groupId' ||
              key === 'product-groupI-name' ||
              key === 'synonyms' ? (
                ''
              ) : (
                <div className="pdp_mf pb-1.5" key={key}>
                  <p className="pdpDetailsHeading capitalize">{key.replace(/_|\-/, ' ')}</p>
                  {console.log(key)}
                  <p className="name text-left">
                    {' '}
                    {typeof value === 'object' ? `${value.key} - ${value.label}` : value}
                  </p>
                </div>
              )}
            </>
          ))}
          <div className="spec-more">{!showAll && <button onClick={() => setShowAll(true)}>View More</button>}</div>
          <div className={'transition-opacity duration-200 ease-linear ' + (showAll ? 'opacity-100' : 'opacity-0 ')}>
            {showAll && (
              <>
                <div className="">
                  {Object.entries(attributes)
                    .slice(15, 26)
                    .map(([key, value]) => (
                      <>
                        {key === 'grade' ||
                        key === 'purity' ||
                        key === 'molecular-formula' ||
                        key === 'chemical-formula' ||
                        key === 'shelf-life' ||
                        key === 'country-of-origin' ||
                        key === 'packaging-size' ||
                        key === 'vendor-name' ||
                        key === 'vendor-code' ||
                        key === 'MOQ' ||
                        key === 'product-groupId' ||
                        key === 'product-group-name' ||
                        key === 'synonyms' ? (
                          ''
                        ) : (
                          <div className="pdp_mf pb-1.5 " key={key}>
                            <p className="pdpDetailsHeading capitalize">{key.replace(/_|\-/, ' ')}</p>

                            <p className="name text-left">
                              {value}
                              {/* {typeof value === 'object' ? `${value?.key} - ${value?.label}` : value} */}
                            </p>
                          </div>
                        )}
                      </>
                    ))}
                  <div className="spec-more">
                    <button
                      onClick={() => {
                        setShowAll(false);
                        scrollToId('scroll-to-product-description');
                      }}
                    >
                      View Less
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
