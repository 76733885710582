import React, { useEffect, useState } from 'react';
import LanguageSwitcher from 'components/commercetools-ui/language-switcher';
import Typography from 'components/commercetools-ui/typography';
import { useFormat } from 'helpers/hooks/useFormat';
import { isLiveReference, Reference, ReferenceLink } from 'helpers/reference';
import Image, { MediaItemWithMods } from 'frontastic/lib/image';
import Column, { Link, Column as FooterColumn } from './column';
import { renderIcon } from './renderIcon';
import NextLink from 'next/link';
export interface Props {
  columns: FooterColumn[];
  copyright?: string;
  copyrightLinks?: Link[];
  logo: MediaItemWithMods;
  logoLink: Reference;
}

const Footer: React.FC<Props> = ({ columns, copyright, copyrightLinks, logo, logoLink }) => {
  // console.log('test>>>>>>>>', logo);
  const { formatMessage } = useFormat({ name: 'common' });
  const [phoneNumber, setPhoneNumber] = useState({ name: '', reference: {} });

  useEffect(() => {
    if (copyrightLinks?.length > 0) {
      const phoneNum = copyrightLinks.filter((el) => {
        if (!/[a-zA-Z]/g.test(el.name)) {
          return el;
        }
      });
      setPhoneNumber(phoneNum[0]);
    }
  }, [copyrightLinks]);

  return (
    <footer aria-label="footer ">
      <div className="footer mx-0 w-full bg-white px-4 dark:bg-transparent lg:px-4">
        <div className="w-full px-2 xl:grid xl:grid-cols-2 xl:gap-8">
          <div
            className={`grid w-full grid-cols-1 gap-10 md:gap-10 md:grid-cols-${(
              columns.length + 1
            ).toString()} xl:col-span-2`}
          >
            {/* <ReferenceLink target={logoLink} className=""> */}
            <span className="sr-only">Catwalk</span>
            <NextLink href="/">
              <div className="footer-logo cursor:pointer">
                <Image media={logo} className="dark:invert" layout="fill" objectFit="contain" alt="Logo" />
              </div>
            </NextLink>
            {/* </a> */}
            {/* </ReferenceLink> */}
            {columns?.map((column, index) => (
              <div key={index} className="md:mx-6 md:flex md:justify-center">
                <Column column={column} />
              </div>
            ))}
            {/* <div className="justify-start md:justify-center">
              <div className="flex space-x-2 md:justify-start">
                {renderIcon('speaker')}
                <h3 className="text-sm font-medium text-gray-800 dark:text-light-100">
                  <Typography>{formatMessage({ id: 'language', defaultMessage: 'Language' })}</Typography>
                </h3>
              </div>
              <LanguageSwitcher className="p-4 md:px-8" />
            </div> */}
          </div>
        </div>
      </div>
      {copyright && (
        <div className="footer-add place-content-between border-t p-4 sm:px-10">
          {/* <p className="text-xs text-white sm:text-sm">© {copyright}</p> */}
          {window.innerWidth < 481 ? (
            <>
              <p className="footer-mail">
                <span>{copyright}</span> | {phoneNumber.name}
              </p>
              <ul className="footer-phone">
                {copyrightLinks?.map(
                  (item, i) =>
                    item.name !== phoneNumber.name && (
                      <li key={i} className="text-xs">
                        <p className="footer-address">
                          <Typography>{item.name}</Typography>
                        </p>
                      </li>
                    ),
                )}
              </ul>
            </>
          ) : (
            <>
              <p className="footer-mail"> {copyright}</p>
              <ul className="footer-phone">
                {copyrightLinks?.map((item, i) => (
                  <li key={i} className="text-xs">
                    <p className="footer-address">
                      <Typography>{item.name}</Typography>
                    </p>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      )}
    </footer>
  );
};

export default Footer;
