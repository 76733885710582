/* eslint-disable prettier/prettier */
import { FAQ_PAGE_VIEWED } from 'helpers/constants/events';
import React, { useCallback, useEffect, useState } from 'react';
import Markdown from 'components/commercetools-ui/content/markdown';

const ContentFAQ = ({ data }) => {
  const faqAttributes = data.data.dataSource.attributes;
  const [openTab, setOpenTab] = useState(1);

  const handleFAQAnalytics = useCallback(async () => {
    gtag('event', FAQ_PAGE_VIEWED, {});
  }, []);
  useEffect(() => {
    handleFAQAnalytics();
  }, [handleFAQAnalytics]);


  const faqLeftItems = [
    'Top Queries',
    'Shipping, Tracking and Delivery',
    'Cancellation and Modification',
    'Returns and exchange',
    'Signup and Login',
    'Payments',
    'Email verification policy',
    'Customisation',
  ];
  const [selectedFaq, setSelectedFaq] = useState(null);

  const scrollToId = (f) => {
    setSelectedFaq(f);
    const mText = f.toLowerCase();
    const index = mText.indexOf(' ');
    let text = index > -1 ? mText.substr(0, mText.indexOf(' ')) : mText;
    if (openTab === 2) {
      text = 's-' + text;
    }
    const ele: HTMLElement = document.getElementById(text);
    ele ? ele.scrollIntoView() : window.scrollTo(0, 0);
  };

  let bTopEntries = faqAttributes.faqBuyer.content.map(y => y.data.target?.fields.faqType);
  bTopEntries = [...new Set(bTopEntries)];
  const firstBuyer = (bTopEntries && bTopEntries.length > 0) ? bTopEntries[0] : faqLeftItems[0];

  let sTopEntries = faqAttributes.faqSeller.content.map(y => y.data.target?.fields.faqType);
  sTopEntries = [...new Set(sTopEntries)];
  const firstSeller = (sTopEntries && sTopEntries.length > 0) ? sTopEntries[0] : faqLeftItems[0];


  const isDataAvailable = (faqTitle: any, content: any) => {
    const foundEntries = content.filter((item) => item.data.target?.fields.faqType?.toLowerCase() === faqTitle?.toLowerCase());
    return foundEntries.length > 0;
  };

  const isDataAvailableForList = (title: string) => {
    const x = isDataAvailable(title, openTab === 1 ? faqAttributes.faqBuyer.content : faqAttributes.faqSeller.content)
    return x;
  };

  const faqs = faqLeftItems.map((f, index) => {
    let cNames = 'faqListItems';
    if (f === selectedFaq) {
      cNames += ' faqListItemActive';
    }
    if (!selectedFaq && openTab === 1 && f.toLowerCase() === bTopEntries[0].toLowerCase()) {
        cNames += ' faqListItemActive';
    }
    if (!selectedFaq && openTab === 2 && f.toLowerCase() === sTopEntries[0].toLowerCase()) {
        cNames += ' faqListItemActive';
    }
    if (isDataAvailableForList(f))
    return (
      <li key={f} className={cNames} onClick={() => scrollToId(f)}>
        {f}
      </li>
    );
  });

  return (
    <div>
      <div
        className="faqLeftContainer pt-[150px]"
        style={{ position: 'absolute', width: '300px', marginLeft: '84px', paddingRight: '20px' }}
      >
        <ul className="faqList">{faqs}</ul>

        <div className="faqAdditionalInfo">
          <p className="faqAdditonalTitle">
            {faqAttributes?.faqSidebar?.content[0]?.data?.target?.fields?.title || 'Text not found'}
          </p>
          <p className="faqAdditionalPara">
            {faqAttributes?.faqSidebar?.content[0]?.data?.target?.fields?.description || 'Text not found'}
          </p>
          <p className="faqAdditonalTitle">
            {faqAttributes?.faqSidebar?.content[1]?.data?.target?.fields?.title || 'Text not found'}
          </p>
         <p className="faqAdditionalPara"> <Markdown text={faqAttributes?.faqSidebar?.content[1]?.data?.target?.fields?.description || 'Text not found'}></Markdown>
      </p> 
        </div>
        <div className="faqLeftBottomWrap">
          <p className="faqLeftBottomTitle">
            {faqAttributes?.faqSidebar?.content[2]?.data?.target?.fields?.buttonName || 'Text not found'}
          </p>
          <p className="faqLeftBottomBtnWrap">
            <a href={faqAttributes?.faqSidebar?.content[2]?.data?.target?.fields?.buttonLink}>
              <button>
                {faqAttributes?.faqSidebar?.content[2]?.data?.target?.fields?.buttonText || 'Text not found'}
              </button>
            </a>
          </p>
        </div>
      </div>
      <div className="faqContainer mx-auto mt-[110px] px-5 md:px-0 lg:px-3">
        <div className="faqWrap gap-[20px] md:grid lg:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-6">
          <div className="faqRightContainer lg:col-span-4 xl:col-span-5">
            <div className="plpBreadcrumbWrap mt-[80px]">
              <span>
                <a href="/">Home Page</a>
              </span>{' '}
              / <span>Frequently asked questions</span>
            </div>
            <div className="plpCategoryTitle">
              <p className="plpCategoryHead capitalize">Frequently asked questions</p>
            </div>
            <div className="faqRightWrap">
              <ul className="faqTabsOuter mb-0 flex list-none flex-row flex-wrap" role="tablist">
                <li className="faqTabsItem -mb-px flex-auto text-center last:mr-0">
                  <a
                    className={'' + (openTab === 1 ? 'faqTabsItemColor' : 'faqTabsItemColorInactive')}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(1);
                      setSelectedFaq(null)
                    }}
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                  >
                    BUYER
                  </a>
                </li>
                <li className="faqTabsItem -mb-px mr-2 flex-auto text-center last:mr-0">
                  <a
                    className={'' + (openTab === 2 ? 'faqTabsItemColor' : 'faqTabsItemColorInactive')}
                    onClick={(e) => {
                      e.preventDefault();
                      setOpenTab(2);
                      setSelectedFaq(null)
                    }}
                    data-toggle="tab"
                    href="#link2"
                    role="tablist"
                  >
                    SELLER
                  </a>
                </li>
              </ul>
              <div className="relative mb-6 flex w-full min-w-0 flex-col break-words rounded bg-white shadow-lg">
                <div className="flex-auto px-4 py-5" style={{ height: 'auto' }}>
                  <div className="tab-content tab-space">
                    <div className={openTab === 1 ? 'block' : 'hidden'} id="link1">
                    <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Top Queries', faqAttributes.faqBuyer.content)
                            ? ''
                            : 'hidden'
                        }`}
                      >Top Queries</p>

                      {faqAttributes.faqBuyer.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Top Queries' && (
                            <>
                              <div tabIndex={0} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="shipping,"></span>
                            </>
                          )}
                        </span>
                      ))}
                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Shipping, Tracking and delivery', faqAttributes.faqBuyer.content)
                            ? ''
                            : 'hidden'
                        }`}
                      >
                        Shipping, Tracking and delivery
                      </p>

                      {faqAttributes.faqBuyer.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Shipping, Tracking and delivery' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="cancellation"></span>
                            </>
                          )}
                        </span>
                      ))}
                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Cancellation and Modification', faqAttributes.faqBuyer.content)
                            ? ''
                            : 'hidden'
                        }`}
                      >
                        Cancellation and Modification
                      </p>

                      {faqAttributes.faqBuyer.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Cancellation and Modification' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="returns"></span>
                            </>
                          )}
                        </span>
                      ))}

                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Returns and exchange', faqAttributes.faqBuyer.content) ? '' : 'hidden'
                        }`}
                      >
                        Returns and exchange
                      </p>

                      {faqAttributes.faqBuyer.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Returns and exchange' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="signup"></span>
                            </>
                          )}
                        </span>
                      ))}

                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Signup and Login', faqAttributes.faqBuyer.content) ? '' : 'hidden'
                        }`}
                      >
                        Signup and Login
                      </p>

                      {faqAttributes.faqBuyer.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Signup and Login' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="payments"></span>
                            </>
                          )}
                        </span>
                      ))}
                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Payments', faqAttributes.faqBuyer.content) ? '' : 'hidden'
                        }`}
                      >
                        Payments
                      </p>

                      {faqAttributes.faqBuyer.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Payments' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="email"></span>
                            </>
                          )}
                        </span>
                      ))}
                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Email verification policy', faqAttributes.faqBuyer.content)
                            ? ''
                            : 'hidden'
                        }`}
                      >
                        Email verification policy
                      </p>

                      {faqAttributes.faqBuyer.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Email verification policy' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="customisation"></span>
                            </>
                          )}
                        </span>
                      ))}

                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Terms and Conditions', faqAttributes.faqBuyer.content) ? '' : 'hidden'
                        }`}
                      >
                        Terms and Conditions
                      </p>

                      {faqAttributes.faqBuyer.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Terms and Conditions' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                            </>
                          )}
                        </span>
                      ))}
                    </div>

                    {/* Seller Tab data */}
                    <div className={openTab === 2 ? 'block' : 'hidden'} id="link2">
                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Top Queries', faqAttributes.faqSeller.content) ? '' : 'hidden'
                        }`}
                      >
                        Top Queries
                      </p>

                      {faqAttributes.faqSeller.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Top Queries' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="s-shipping,"></span>
                            </>
                          )}
                        </span>
                      ))}
                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Shipping, Tracking and delivery', faqAttributes.faqSeller.content)
                            ? ''
                            : 'hidden'
                        }`}
                      >
                        Shipping, Tracking and delivery
                      </p>

                      {faqAttributes.faqSeller.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Shipping, Tracking and delivery' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="s-cancellation"></span>
                            </>
                          )}
                        </span>
                      ))}
                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Cancellation and Modification', faqAttributes.faqSeller.content)
                            ? ''
                            : 'hidden'
                        }`}
                      >
                        Cancellation and Modification
                      </p>

                      {faqAttributes.faqSeller.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Cancellation and Modification' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="s-returns"></span>
                            </>
                          )}
                        </span>
                      ))}

                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Returns and exchange', faqAttributes.faqSeller.content) ? '' : 'hidden'
                        }`}
                      >
                        Returns and exchange
                      </p>

                      {faqAttributes.faqSeller.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Returns and exchange' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="s-signup"></span>
                            </>
                          )}
                        </span>
                      ))}

                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Signup and Login', faqAttributes.faqSeller.content) ? '' : 'hidden'
                        }`}
                      >
                        Signup and Login
                      </p>

                      {faqAttributes.faqSeller.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Signup and Login' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="s-payments"></span>
                            </>
                          )}
                        </span>
                      ))}
                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Payments', faqAttributes.faqSeller.content) ? '' : 'hidden'
                        }`}
                      >
                        Payments
                      </p>

                      {faqAttributes.faqSeller.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Payments' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="s-email"></span>
                            </>
                          )}
                        </span>
                      ))}
                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Email verification policy', faqAttributes.faqSeller.content)
                            ? ''
                            : 'hidden'
                        }`}
                      >
                        Email verification policy
                      </p>

                      {faqAttributes.faqSeller.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Email verification policy' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                              <span id="s-customisation"></span>
                            </>
                          )}
                        </span>
                      ))}

                      <p
                        className={`faqInnerTitle ${
                          isDataAvailable('Terms and Conditions', faqAttributes.faqSeller.content) ? '' : 'hidden'
                        }`}
                      >
                        Terms and Conditions
                      </p>

                      {faqAttributes.faqSeller.content.map((item, index) => (
                        <span key={index}>
                          {item.data.target?.fields.faqType == 'Terms and Conditions' && (
                            <>
                              <div tabIndex={0} key={index} className="collapse-arrow faqAccordionOuter collapse">
                                <div className="faqAccordionOuterTitle collapse-title ">
                                  <p>{item.data.target?.fields.faqTitle}</p>
                                </div>
                                <div className="faqAccordionInnerPara collapse-content">
                                  <p>{item.data.target?.fields.faqDescription}</p>
                                </div>
                              </div>
                            </>
                          )}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentFAQ;
