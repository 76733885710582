import Markdown from 'components/commercetools-ui/content/markdown';
import { PRIVACY_POLICY_PAGE_VIEWED } from 'helpers/constants/events';
import React, { useCallback, useEffect, useState } from 'react';
// import ReactMarkdown from 'react-markdown'

const ContentPrivacy = ({ data }) => {
  const aboutAttributes = data.data.dataSource.attributes;
  const modifiedArr = aboutAttributes.component.content;

  const handlePrivacyPolicyAnalytics = useCallback(async () => {
    gtag('event', PRIVACY_POLICY_PAGE_VIEWED, {});
  }, []);
  useEffect(() => {
    handlePrivacyPolicyAnalytics();
  }, [handlePrivacyPolicyAnalytics]);
  return (
    <>
      <div className="container mx-auto mt-[110px] px-5 md:px-2 lg:px-10">
        <div className="privacyPolicyContainer">
          <div className="plpBreadcrumbWrap">
            <span>
              <a href="/">Home Page</a>
            </span>
            / <span>Privacy Policy</span>
          </div>
          <div className="plpCategoryTitle">
            <p className="plpCategoryHead capitalize">Privacy Policy</p>
          </div>
          {aboutAttributes.component.content.map((item, index) => (
            <span key={index}>
              {item.nodeType != 'paragraph' && (
                <>
                  <Markdown className="privacyPolicyTitle" text={item?.data?.target?.fields?.title || ''} />

                  <Markdown className="privacyPolicyDesciption" text={item?.data?.target?.fields?.description} />
                </>
              )}
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

export default ContentPrivacy;
