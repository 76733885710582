import React from 'react';
import Link from 'next/link';
import { searchQueryText } from '../algolia-search/searchbox';
import Breadcrumb from 'components/commercetools-ui/breadcrumb';
import { useRouter } from 'next/router';

export default function NoResultsFound() {
  const router = useRouter();
  const url_string = window.location.href;
  const url = new URL(url_string);
  const punchUrl = url.href;
  // code added
  if(punchUrl === 'https://qa.allverz.com/session_id' || 
     punchUrl === 'https://qa.allverz.com/HOOK_URL'
  ){
    router.push('/'); 
  }
  return (
    <div className="">
      <div className="plpProductTilesWrap">
        <div className="flex flex-col">
          <span className="ml-[10%] mb-[-10%]">
            <div className="plpBreadcrumbWrap">
              <Breadcrumb Separator="/" categorySlugs={['No Result Found']}>
                {['No Result Found']}
              </Breadcrumb>
            </div>
          </span>
          <h1 className="no-result-main-text">
            No results found
            <span>&nbsp;{searchQueryText}</span>
          </h1>
          <p className="no-result-sub-text">Try searching again by a different spelling or keywords. or Contact us</p>
          <div className="no-result-btns flex flex-col md:flex-row">
            <button className="headerEnquirybtn">
              <Link href={'/'}>Back to Home</Link>
            </button>
            <button
              className="headerAccBtn"
              onClick={(e) => {
                e.preventDefault();
                router.push('/contact-us');
              }}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
